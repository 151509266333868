import React, { useMemo, useState } from "react";
import Preinscriptions from "./components/forms/preinscription/preinscriptions";
import Reinscriptions from "./components/forms/reinscriptions/reinscriptions";
import Header from "./components/header";
import About from "./components/sections/about";
import HomeSection from "./components/sections/home";
import Services from "./components/sections/services";
import OurWork from "./components/sections/our-work/our-work";
import OurTeam from "./components/sections/our-team/our-team";
import Testimonials from "./components/sections/testimonials/testimonials";
import Companies from "./components/sections/companies/companies";
import Footer from "./components/footer";
import WhatsAppMessenger from "./components/whatsapp-messenger";
import EventPreinscripciones from "./components/events/event-preinscripciones";
import { ThemeProvider } from '@mui/material/styles';
import AppMuiTheme from "./utils/app-mui-theme";
import TeamAssets2025 from "./components/sections/our-team/team-assets-2025";

function App() {
  return (
    <>
      <ThemeProvider theme={AppMuiTheme}>
          <Header />
          <EventPreinscripciones />
          <Preinscriptions />
          <Reinscriptions />
          <HomeSection />
          <Services />
          <About />
          <OurWork />
          <OurTeam memberList={TeamAssets2025} memberType="team" />
          <OurTeam memberList={TeamAssets2025} memberType="teacher" noDescription />
          <Testimonials />
          <Companies />
          <Footer />
          <WhatsAppMessenger />
      </ThemeProvider>
    </>
  );
}

export default App;
